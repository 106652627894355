import { RenderOnceInViewport } from '../../../../app/_components/performance/RenderOnceInViewport.client'
import { useTrackPageView } from '../../../../components/pages/Page/hooks/useTrackPageView'
import { PageCategory } from '../../../../containers/SignalContainer'
import { useFixturesContext } from '../../../containers/fixtures'
import { ErrorLink } from '../../../containers/fixtures/IFixtures'
import { NostoProductRecos } from '../../NostoProductRecos'
import { ErrorView } from '../ErrorView'

import { useTrackNostoNotFoundPageView } from './hooks/useTrackNostoNotFoundPageView'
import { RecommendationsWrapper } from './styles'

import type { ReactElement } from 'react'

export const NotFound = (): ReactElement => {
  useTrackNostoNotFoundPageView()
  useTrackPageView({ handle: 'Not Found', category: PageCategory.Not_Found })

  const {
    globals: { canonicalBaseUrl },
    pages: {
      notFound: { canonicalPath: canonical404Path, image, links, subheading, heading },
    },
  } = useFixturesContext()

  const orderedLinks: readonly ErrorLink[] = [links.shopWomen, links.shopMen]

  return (
    <ErrorView
      heading={heading}
      subheading={subheading}
      canonicalUrl={`${canonicalBaseUrl.home}${canonical404Path}`}
      imageAlt={image.alt}
      imageSrc={image.src}
      links={orderedLinks}
    >
      <RecommendationsWrapper>
        <RenderOnceInViewport>
          <NostoProductRecos
            analyticsContext={{ category: 'not-found-page::notfound-nosto-2' }}
            nostoPlacementId='notfound-nosto-2'
            titleAlignmentDesktop='left'
          />

          <NostoProductRecos
            analyticsContext={{ category: 'not-found-page::notfound-nosto-1' }}
            nostoPlacementId='notfound-nosto-1'
            titleAlignmentDesktop='left'
          />
        </RenderOnceInViewport>
      </RecommendationsWrapper>
    </ErrorView>
  )
}
