import { useRouter } from 'next/router'
import { useMemo } from 'react'

export const usePurePathname = () => {
  const { pathname: pageFilePath, query } = useRouter()
  const pathname = useMemo(() => {
    let pathname = pageFilePath
    Object.entries(query).forEach(([key, value]) => {
      pathname = pathname.replace(`[${key}]`, `${value}`)
    })
    return pathname
  }, [pageFilePath, query])
  return pathname
}
